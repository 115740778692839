* {
    box-sizing: border-box;
}

body {
    width: 100%;
    min-height: 100vh;
    background: url("../public/img/background.jpg");
    background-attachment: fixed;
    height: 100%;
    margin: 0 auto;
    padding-top: 66px;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

/***** Top navigation *****/
.top_navigation {
    box-shadow: 0 2px 15px 0 rgba(100, 100, 100, .05);
    opacity: .98;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.top_navigation .logo_img {
    max-height: 50px;
    margin-right: 15px;
}

.top_navigation .navbar_item {
    display: block;
    position: relative;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    padding: 10px;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
}

.top_navigation .logged_user_fullName {
    color: #5C5E60;
    cursor: pointer;
}

.top_navigation .navbar_item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 7px;
    left: 0;
    background: #E6B238;
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: .25s linear;
}

.top_navigation .navbar_item:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.top_navigation a.navbar_item:hover {
    color: #E6B238;
}

.top_navigation .navbar_item.active {
    color: #E6B238;
}

.top_navigation .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

/***** Loader *****/
.loader_container {
    height: calc(100vh - 74px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/***** Auth layout *****/
.auth_wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 66px);
    text-align: center;
}

.auth_box {
    max-width: 400px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.auth_header {
    background: #E6B238;
    padding: 20px 30px;
}

.auth_header h2 {
    color: #fff;
    font-size: 32px;
    text-align: center;
    margin: 0;
}

.auth_form {
    background: #fbfbfb;
    padding: 20px 30px;
}

.btn_success {
    background: #BBD870 !important;
    color: #5C5E60 !important;
}

.btn_danger {
    background: #dc3545 !important;
    color: #fff !important;
}

.btn_delete {
    background: transparent !important;
    color: #dc3545 !important;
}

.btn_outlined {
    background: #fff !important;
    color: #5C5E60 !important;
    border: 1px solid #c9c9c9 !important;
}

.btn_success:disabled,
.btn_disabled:disabled {
    opacity: 0.6;
}

.login_register_link {
    color: rgba(0, 0, 0, 0.87);
}

.main_title {
    color: #5e5e5e;
    font-size: 22px;
}

.error_message {
    position: absolute;
    bottom: -16px;
    left: 5px;
    color: #ff0000;
    font-style: italic;
    font-size: 13px;
    line-height: 1.1;
    z-index: 2;
    text-align: left;
}

.terms_of_use_link {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
}

.search_input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search_input_container .MuiFormControl-root.MuiFormControl-fullWidth {
    flex: 0 0 300px;
}

.input_container {
    display: flex;
    align-items: center;
    justify-content: start;
}

.input_container>div {
    flex: 0 0 300px;
    margin-left: 10px;
    margin-right: 10px;
}

.input_container>div:first-of-type {
    flex: 0 0 300px;
    margin-left: 0;
}

.input_container>div:last-of-type {
    margin-right: 0;
}

.input_container>button:last-of-type {
    margin-left: 20px;
}

.input_container>div {
    flex: 0 0 25%;
}

.contact_box {
    display: flex;
    max-width: 800px;
    margin: auto;
    margin-bottom: 30px;
}

.contact_box .contact_single_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact_box .contact_single_row>div:first-of-type {
    margin-right: 24px;
}

.contact_box .contact_info {
    background: #BBD870;
    color: #5C5E60;
    padding: 50px;
    margin-left: 19px;
    box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact_box .contact_info_row {
    margin-bottom: 15px;
}

.contact_box .rounded_button {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.25);
}

.contact_box .contact_info_row a {
    color: #5C5E60;
}

.order_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #BBD870;
    color: #5C5E60;
    padding: 5px 10px;
    margin: 10px 0 3px;
}

.order_heading h3 {
    margin: 0;
    padding: 0 20px;
    font-size: 18px;
}

.order_heading p {
    display: flex;
    align-items: center;
}

.order_customer_additional_info {
    margin-left: 20px;
}

.order_customer_additional_info p:first-of-type {
    margin-bottom: 3px;
}

.order_customer_additional_info svg {
    margin-right: 3px;
}

.order_customer_basic_info {
    display: flex;
}

.order_heading .delivery_time {
    font-weight: bold;
    font-size: 16px;
}

.orders_container .single_order {
    color: #5e5e5e;
    word-break: break-all;
    margin: 5px;
    background: #fff;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.orders_container .single_order.oatmeal_order {
    background: #dc3545;
    color: #fff;
}

.preparation {
    border: 1px solid #2e7d32;
}

.finished {
    border: 1px solid #d32f2f;
}

.orders_container .single_order .single_order_heading_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_finished_btn {
    background: #E6B238 !important;
    color: #fff !important;
    padding: 3px 8px !important;
}

.orders_container h4 {
    font-size: 18px;
    margin-bottom: 0;
}

.orders_container .order_list {
    font-size: 16px;
}

.orders_container .order_list.oatmeal_order {
    color: #dc3545;
}

.orders_container .order_total_amount {
    background: #5C5E60;
    color: #fff;
    padding: 8px;
    margin: 12px 5px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 4px;
}

.order_amount {
    display: inline-block;
    background: #E6B238;
    color: #fff;
    min-width: 25px;
    height: 25px;
    text-align: center;
}

.menu_container .tabs_container {
    display: flex;
    flex-grow: 1;
    height: 250px;
}

.menu_container .menu_items {
    width: calc(100% - 100px);
    padding: 0 40px;
}

.menu_container .menu_item {
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: 15px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    padding: 12px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.menu_container .image_wrapper {
    position: relative;
}

.menu_container .image_wrapper .ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
}

.menu_container .image_wrapper .ribbon span.ribbon_soldout {
    position: absolute;
    top: 19px;
    right: -21px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #8F0808;
    background: linear-gradient(#F70505 0%, #8F0808 100%);
    box-shadow: 0 3px 10px -5px black;
}

.menu_container .image_wrapper .ribbon span.ribbon_soldout::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #8F0808;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F0808;
}

.menu_container .image_wrapper .ribbon span.ribbon_soldout::after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #8F0808;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F0808;
}

.menu_container .image_wrapper img {
    width: 220px;
    height: 170px;
    border-radius: 5px 0 0 5px;
    margin: 0;
    object-fit: cover;
    border-radius: 5px;
}

.menu_container .menu_info {
    color: #818181;
    padding: 0 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.menu_container .menu_info .menu_name {
    color: #20252b;
    font-size: 24px;
    margin-bottom: 0;
}

.menu_container .menu_info .menu_desc {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.menu_container .menu_info .menu_size_wrapper {
    display: flex;
}

.menu_container .menu_info .menu_item_size {
    font-size: 18px;
    margin-right: 20px;
}

.menu_container .menu_info .menu_item_price {
    color: #E6B238;
    margin-left: 4px;
}

.menu_container .menu_info .healthy_icon_container img {
    cursor: pointer;
}

.menu_container .btn_meal_container button:first-of-type {
    margin-right: 8px;
}

.menu_container .btn_meal,
.menu_container .btn_selected_meal {
    background: #fff;
    color: #818181;
    border: 1px solid #e5e5e5;
    min-height: auto;
    padding: 6px 16px;
}

.menu_container .btn_selected_meal {
    background: #5C5E60;
    color: #fff;
    border: 1px solid #5C5E60;
}

.menu_container .btn_meal:hover {
    background: #fff;
    color: #818181;
}

.menu_container .btn_selected_meal:hover {
    background: #5C5E60;
    color: #fff;
}

.sidebar_container .sidebar_header {
    background: #5C5E60;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 450px;
    padding: 0px 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    z-index: 999;
}

.sidebar_container .sidebar_header h2 {
    font-size: 21px;
    margin: 0;
    text-transform: capitalize;
}

.sidebar_container .sidebar_content {
    color: #fff;
    height: calc(100% - 85px);
    margin-top: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px;
}

.sidebar_container .sidebar_item {
    padding: 10px 0;
}

.sidebar_container .sidebar_item+.sidebar_item {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.sidebar_container .meal_name {
    font-size: 20px;
    margin-bottom: 5px;
}

.sidebar_container .meal_size {
    font-size: 16px;
    margin-bottom: 5px;
}

.sidebar_container .meal_ordering {
    display: flex;
    align-items: center;
    justify-content: start;
}

.sidebar_container .meal_ordering .meal_amount {
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin: 0 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
}

.sidebar_container .meal_ordering button {
    width: 32px;
    min-width: 40px;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}

.sidebar_container .meal_ordering button span {
    margin: 0;
}

.sidebar_container .total_amount_container {
    padding: 4px 8px;
    margin-top: 12px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 4px;
}

.sidebar_container .total_amount_container .total_amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
}

.strikethrough {
    position: relative;
}

.strikethrough:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    color: #d32f2f;
    border-top: 3px solid;
    border-color: inherit;
    transform: rotate(-5deg);
}

.sidebar_container b.amount {
    color: #E6B238;
    margin-left: 10px;
}

.sidebar_container .sidebar_btns_container {
    background: #5C5E60;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 450px;
    padding: 15px 0;
    z-index: 999;
}

.sidebar_container .sidebar_btns_container button {
    margin: 0 12px;
}

.sidebar_container .sidebar_btns_container button:first-of-type {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}


.shift_cart_left,
.shift_cart_right {
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.shift_cart_left {
    right: 450px;
}

.shift_cart_right {
    right: 0;
}

.orders_total_amount_container {
    padding: 12px;
    border: 1px solid #818181;
    margin-top: 12px;
    border-radius: 4px;
}

.orders_total_amount_container .total_amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portion_row_container {
    display: flex;
}

@media (max-width: 992px) {
    .top_navigation .navbar_item {
        padding: 10px 0;
    }

    .top_navigation .navbar_item:hover::before {
        visibility: hidden;
    }

    .auth_box {
        padding: 0;
    }

    .auth_form {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .menu_container .tabs_container {
        flex-direction: column;
    }

    .menu_container .menu_items {
        width: 100%;
        margin-top: 20px;
        padding: 0;
    }

    .menu_container .menu_item {
        margin-bottom: 10px;
    }

    .menu_container .image_wrapper img {
        width: 150px;
        height: auto;
    }

    .menu_container .menu_info {
        padding: 0px 10px;
    }

    .contact_box {
        flex-direction: column;
    }

    .contact_box .contact_info {
        margin: 0;
        padding: 10px 15px;
    }

    .contact_box .contact_info_row {
        margin-bottom: 10px;
    }

    .input_container {
        flex-direction: column;
        align-items: stretch;
    }

    .input_container>div:first-of-type,
    .input_container>div {
        flex: 1 1 100%;
        width: 100%;
    }

    .input_container>div {
        margin-left: 0;
        margin-right: 0;
    }

    .input_container>button:last-of-type {
        margin-left: 0;
    }

    .search_input_container {
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 12px;
    }

    .search_input_container .MuiFormControl-root.MuiFormControl-fullWidth {
        flex: 1 1 100%;
        width: 100%;
    }

    .selected_meals_container h4 {
        margin-top: 10px;
        font-size: 20px;
    }

    .selected_meals_container h5 {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .menu_container .menu_items {
        margin-top: 15px;
    }

    .menu_container .menu_item {
        flex-direction: column;
        padding: 10px;
    }

    .menu_container .image_wrapper img {
        width: 170px;
    }

    .menu_container .menu_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .menu_container .menu_info .menu_name {
        font-size: 22px;
    }

    .menu_container .menu_info .menu_desc {
        font-size: 15px;
        line-height: 1.4;
        margin: 5px 0;
    }

    .menu_container .menu_info .menu_size_wrapper {
        flex-direction: column;
    }

    .menu_container .menu_info .menu_size {
        text-align: center;
    }

    .menu_container .menu_info .menu_item_size {
        margin: 8px 0;
    }

    .menu_container .menu_info .healthy_icon_container {
        margin-top: 15px;
    }

    .menu_container .btn_meal_container button:first-of-type {
        margin-bottom: 8px;
    }

    .sidebar_container .sidebar_content {
        width: 100%;
    }

    .sidebar_container .sidebar_header,
    .sidebar_container .sidebar_btns_container {
        width: 100%;
    }

    .order_heading {
        flex-direction: column;
    }

    .order_customer_basic_info {
        justify-content: space-around;
        width: 100%;
    }

    .order_customer_additional_info {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 10px 0px 0px 0px;
    }

    .order_customer_additional_info p:first-of-type {
        margin-bottom: 0;
    }

    .portion_row_container {
        flex-direction: column;
    }
}